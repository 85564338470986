import { Box, Typography } from '@mui/material';
import useLocales from '#/lib/hooks/useLocales';
import IconCard from '../icon-cards';
import { IconCardProps } from '../types';

type EightReasonsProps = {
  cards: IconCardProps[];
};

export default function EightReasons({ cards }: EightReasonsProps) {
  const { translate } = useLocales();
  return (
    <Box>
      <Typography variant="h2" textAlign="center" data-aos="fade-down">
        {translate('provisionLanding.eightReasons.title')}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)' },
          gap: { xs: 5, md: '5px 10px' },
        }}
      >
        {cards.map((item, i) => (
          <IconCard {...item} key={i} />
        ))}
      </Box>
    </Box>
  );
}
